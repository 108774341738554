import { Injectable } from "@angular/core";
import { AuthService } from "../auth/services/auth.service";
import { firstValueFrom } from 'rxjs';

@Injectable()
export class Ng1AuthService {

  constructor(private authService: AuthService) {}

  async getAccessToken() {
    return await firstValueFrom(this.authService.getTokenAndRefreshIfExpired());
  }

  currentAccessToken() {
    return this.authService.getAccessToken();
  }
}
