'use strict';

angular
    .module('messaging.directives.emailEditor', [
        'ui.tinymce',
        'angularFileUpload',
        'ngTagsInput',
        'pascalprecht.translate',
        'shared.services.simpleAlertService',
        'shared.components.sbIcon',

        'messaging.constants',
        'messaging.services.messagingService',
        'person.services.personPickerService',
        'shared.directives.sbCheckBox',
        'shared.services.imagesUploadHandler',
        'shared.services.tinymceConfigHelper',
        'personPicker.constants',
    ])
    .directive('emailEditor', [
        '$window',
        'ng1AuthService',
        'messagingService',
        'FileUploader',
        'personPickerService',
        'tinymceConfigHelper',
        'simpleAlertService',
        'imagesUploadHandler',
        'messageMediumType',
        function (
            $window,
            ng1AuthService,
            messagingService,
            FileUploader,
            personPickerService,
            tinymceConfigHelper,
            simpleAlertService,
            imagesUploadHandler,
            messageMediumType
        ) {
            function controller($scope) {
                var maxBytesSizeUpload =
                    $window.EveryBuddy.UploadFileSizeLimitMB * 1048576; //to bytes

                $scope.reciepientSelected;
                $scope.messagingService = messagingService;

                $scope.mbSizeLabel =
                    $window.EveryBuddy.UploadFileSizeLimitMB.toString() + 'MB';
                $scope.title = $scope?.messageTitle ?? '';
                $scope.wholeSchoolClubId = 0;
                $scope.attachToEmail = true;

                    $scope.attachments = [];

                    $scope.messageType = {
                        hasRichContent: true,
                        showTitle: true,
                        showHeadline: false,
                        allowFileAttachments: true,
                        hasFeatureImage: false,
                    };

                personPickerService
                    .getWholeSchoolClubId()
                    .then(function (data) {
                        if (!data.isError) {
                            $scope.wholeSchoolClubId = data;
                        }
                    });

                    $scope.allTags = [];
                    $scope.tags = [];

                $scope.messagingService.getAllTags().then(function (data) {
                    for (var i = data.length; i--; ) {
                        $scope.allTags.push({ name: data[i] });
                    }
                });

                $scope.getTagsTypeAhead = function (query) {
                    return $scope.allTags;
                };

                $scope.tinymceOptions = tinymceConfigHelper.getTinyMceConfig({
                    tables: true,
                    hyperlinks: true,
                    imagesUploadHandler: imagesUploadHandler.uploadImage,
                    imagesUploadUrl: 'dummyUploadUrl', // This is only needed to make the Upload tab appear
                });

                var simpleAlert = function (title, message) {
                    simpleAlertService.simpleAlert({
                        title: title,
                        message: message,
                        okButtonText: 'SB_OK',
                        windowSize: 'lg',
                    });
                };

                $scope.fileUploader = new FileUploader({
                    url: `${window.EveryBuddy.WebAPI}/webapi/WebFileUpload/Post`,
                    autoUpload: true,
                    removeAfterUpload: true,
                    headers: {
                        Authorization: `Bearer ${ng1AuthService.currentAccessToken()}`,
                    },
                });
                $scope.imageUploader = new FileUploader({
                    url: `${window.EveryBuddy.WebAPI}/webapi/WebImageUpload/Post`,
                    autoUpload: true,
                    removeAfterUpload: true,
                    headers: {
                        Authorization: `Bearer ${ng1AuthService.currentAccessToken()}`,
                    },
                });
                $scope.fileUploader.filters.push({
                    name: 'fileTypeFilter',
                    fn: function (item /*{File|FileLikeObject}*/, options) {
                        switch (item.type) {
                            case 'image/jpeg':
                            case 'image/gif':
                            case 'image/png':
                            case 'image/tiff':
                            case 'application/pdf':
                                return true;
                            default:
                                return false;
                        }
                    },
                });

                $scope.fileUploader.filters.push({
                    name: 'fileSizeFilter',
                    fn: function (item /*{File|FileLikeObject}*/, options) {
                        return item.size <= maxBytesSizeUpload;
                    },
                    options: $scope.mbSizeLabel,
                });

                $scope.imageUploader.filters.push({
                    name: 'fileTypeFilter',
                    fn: function (item /*{File|FileLikeObject}*/, options) {
                        switch (item.type) {
                            case 'image/jpeg':
                            case 'image/gif':
                            case 'image/png':
                            case 'image/tiff':
                                return true;
                            default:
                                return false;
                        }
                    },
                });

                //** Prevent same file to be uploaded */
                $scope.fileUploader.filters.push({
                    name: 'fileDuplicateFilter',
                    fn: (uploadItem /*{File|FileLikeObject}*/) => {
                        const duplicated = $scope.attachments.some(
                            (item) => item.displayName === uploadItem.name
                        );
                        return !duplicated;
                    },
                });

                $scope.imageUploader.filters.push({
                    name: 'fileSizeFilter',
                    fn: function (item /*{File|FileLikeObject}*/, options) {
                        return item.size <= maxBytesSizeUpload;
                    },
                    options: $scope.mbSizeLabel,
                });

                var showInvalidFileError = function (
                    filterName,
                    imagesOnly,
                    filterOptions
                ) {
                    var error = '';
                    switch (filterName) {
                        case 'fileTypeFilter':
                            error =
                                'Files of this type are not supported for use as attachments.  Please supply only image files';

                            if (!imagesOnly) {
                                error += ' or PDF documents.';
                            } else {
                                error += '.';
                            }

                            simpleAlert('SB_Unsupported_file_type', error);
                            break;
                        case 'fileSizeFilter':
                            error =
                                'File is larger than the maximum size of ' +
                                filterOptions;

                            simpleAlert('SB_File_is_too_large', error);
                            break;
                        default:
                            simpleAlert(
                                'SB_File_unsuitable',
                                'SB_File_is_unsuitable_for_use'
                            );
                            break;
                    }
                };

                $scope.fileUploader.onWhenAddingFileFailed = function (
                    item /*{File|FileLikeObject}*/,
                    filter,
                    options
                ) {
                    showInvalidFileError(filter.name, false, filter.options);
                };
                $scope.fileUploader.onSuccessItem = function (
                    fileItem,
                    response,
                    status,
                    headers
                ) {
                    var toAdd = {
                        name: response[0].name,
                        displayName: fileItem.file.name,
                        attachmentMediaId: 0,
                        attachmentMediaTypeId: 0,
                    };

                    $scope.attachments.push(toAdd);
                };
                $scope.fileUploader.onErrorItem = function (
                    fileItem,
                    response,
                    status,
                    headers
                ) {
                    simpleAlert(
                        'SB_Unable_to_upload_file',
                        'SB_Unable_to_upload_file'
                    );
                };

                $scope.imageUploader.onWhenAddingFileFailed = function (
                    item /*{File|FileLikeObject}*/,
                    filter,
                    options
                ) {
                    showInvalidFileError(filter.name, true, filter.options);
                };
                $scope.imageUploader.onSuccessItem = function (
                    fileItem,
                    response,
                    status,
                    headers
                ) {
                    var toAdd = {
                        name: response[0].name,
                        displayName: fileItem.file.name,
                        attachmentMediaId: 0,
                        attachmentMediaTypeId: 0,
                    };

                    $scope.featuredImage = toAdd;
                };
                $scope.imageUploader.onErrorItem = function (
                    fileItem,
                    response,
                    status,
                    headers
                ) {
                    simpleAlert(
                        'SB_Unable_to_upload_image',
                        'SB_Unable_to_upload_image'
                    );
                };
                $scope.clearFeaturedImage = function () {
                    $scope.featuredImage = null;
                };
                $scope.removeAttachment = function (item) {
                    $('input#attachmentUpload').val(''); // Clear the file input so that the same file can be uploaded again
                    var indexToRemove = -1;
                    for (var i = $scope.attachments.length; i--; ) {
                        var current = $scope.attachments[i];
                        if (current.name === item.name) {
                            indexToRemove = i;
                            break;
                        }
                    }
                    if (indexToRemove > -1) {
                        $scope.attachments.splice(indexToRemove, 1);
                    }
                };

                $scope.prepopulated =
                    $scope.messagingService.hasRecipientSelected($scope);

                    $scope.reset = function () {
                        $scope.title = $scope?.messageTitle ?? '';
                        $scope.headline = '';
                        $scope.htmlBody = '';
                        $scope.plainBody = '';
                        $scope.featuredImage = null;
                        $scope.attachments = [];
                        $scope.fileUploader.clearQueue();
                        $scope.imageUploader.clearQueue();
                        $scope.tags = [];
                        $scope.attachToEmail = true;
                    };

                    $scope.toggleAttachToEmail = () => {
                        $scope.attachToEmail = !$scope.attachToEmail;
                    };

                $scope.sendMessage = function () {
                    this.postMessage = function (newMessageModel) {
                        $scope.sending = true;
                        $scope.messagingService
                            .sendNewMessage(newMessageModel)
                            .then(function (data) {
                                if (data == null || data.isError) {
                                    const separator = !data.errorData?.data
                                        .Message
                                        ? ''
                                        : ' - ';
                                    const msg =
                                        'Your message was not sent' +
                                        separator +
                                        data.errorData.data.Message;

                                    simpleAlert(
                                        'SB_Problem_sending_your_message',
                                        msg
                                    );
                                } else {
                                    $scope.messagingService
                                        .getThisOrganisationsSmsBalance()
                                        .then(function (data) {
                                            $scope.smsBalance = data;
                                        });

                                    simpleAlert(
                                        'SB_Communication_Sent',
                                        'Communications will appear in the message center, once they have been processed, this could take a few minutes.'
                                    );
                                    if (!$scope.prepopulated) {
                                        $scope.messagingService.resetGroupContainers(
                                            $scope
                                        );
                                    }
                                    $scope.reset();
                                }

                                $scope.sending = false;
                            })
                            .finally(() => {
                                $('input#attachmentUpload').val(''); // Clear the file input so that the same file can be uploaded again
                            });
                    }.bind(this);

                    this.getTags = function () {
                        var tags = [];

                        for (var i = $scope.tags.length; i--; ) {
                            tags.push($scope.tags[i].name);
                        }

                            return tags;
                        }.bind(this);

                    if (
                        !$scope.messagingService.validateSendMessage(
                            $scope,
                            simpleAlert
                        )
                    ) {
                        return;
                    }

                    this.messageModel = function () {
                        let newMessageModel = {
                            recipients: [],
                            messageMedium: {
                                mediumId: messageMediumType.Email.id,
                                title: $scope.title,
                                headline: $scope.headline,
                                htmlBody: $scope.htmlBody,
                                plainBody: $scope.plainBody,
                                featuredImage: $scope.featuredImage,
                                attachments: $scope.attachments,
                                tags: this.getTags(),
                                isEvent: $scope.isEvent,
                                calendarEventId: $scope.calendarEventId,
                                teamId: $scope.teamId,
                            },
                            attachToEmail:
                                $scope.attachToEmail &&
                                $scope.showAttachToEmail,
                        };

                        $scope.messagingService.prepareRecipientGroups(
                            newMessageModel,
                            $scope.recipients,
                            $scope.calendarEventId
                        );

                        return newMessageModel;
                    }.bind(this);

                    // Confirmation alert requried for whole school.
                    if (
                        $scope.messagingService.selectedWholeSchool(
                            $scope.recipients
                        )
                    ) {
                        $scope.sending = true;

                        var wholeSchoolmodalInstance =
                            simpleAlertService.simpleAlert({
                                title: 'SB_Whole_School',
                                message: 'SB_Communication_WholeSchool_Warning',
                                windowSize: 'sm',
                                okButtonText: 'SB_Confirm',
                                cancelButtonText: 'SB_Cancel',
                            });

                        wholeSchoolmodalInstance.result.then(
                            function () {
                                this.postMessage(this.messageModel());
                            }.bind(this),
                            function () {
                                $scope.sending = false;
                            }
                        );

                            return;
                        }

                        this.postMessage(this.messageModel());
                    };
                }

            return {
                restrict: 'E',
                templateUrl:
                    '/Scripts/app/messaging/directives/email-editor.template.html?v=' +
                    $window.EveryBuddy.Version,
                scope: {
                    recipients: '=',
                    showTags: '<',
                    isEvent: '<',
                    calendarEventId: '<',
                    teamId: '<',
                    showAttachToEmail: '<',
                    messageTitle: '<', // when set will be set as ready only.
                },
                controller: ['$scope', controller],
            };
        },
    ]);
