export const PIN_WIDTH = 32 as const;

export const PIN_STYLES: string = `
    width: ${PIN_WIDTH}px;
    height: ${PIN_WIDTH}px;
    display: block;
    left: -${PIN_WIDTH / 2}px;
    top: -${PIN_WIDTH / 2}px;
    position: relative;
    border-radius: ${PIN_WIDTH}px ${PIN_WIDTH}px 0;
    transform: rotate(45deg);
    border: 2px solid #FFFFFF;
  ` as const;

export const PIN_LABEL_STYLES: string = `
    transform-origin: center;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: medium;
    text-align: center;
    white-space: nowrap;
  ` as const;
