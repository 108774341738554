import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DivIcon, FeatureGroup, Map, Marker, easyButton, featureGroup, map, tileLayer } from 'leaflet';
import 'leaflet-easybutton';
import { PIN_LABEL_STYLES, PIN_WIDTH } from './map-style-settings.const';
import { PIN_STYLES } from './map-style-settings.const';

@Component({
  selector: 'sb-map-display',
  standalone: true,
  templateUrl: './map-display.component.html',
  styleUrl: './map-display.component.scss'
})
export class MapDisplayComponent implements AfterViewInit, OnChanges {
  @Input() defaultMapPosition?: MapCentre;
  @Input() markers: MapMarker[] = [];
  @Input() allowPinDrop: boolean = false;
  @Input() tileURL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  @Input() attributionString = '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
  @Input() markerColour: string = 'silver';
  @Input() labelColour: string = 'black';
  @Input() labelAbovePin: boolean = false;
  @Input() height: string;
  @Input() skipCentering: boolean = false;
  @Output() dropPinEvent = new EventEmitter<MapMarker>();
  @Output() clickPinEvent = new EventEmitter<MapMarker>();
  private pinWidth = PIN_WIDTH;
  private pinStyles = PIN_STYLES;
  private pinLabelStylesTemplate = PIN_LABEL_STYLES;
  private map!: Map;
  private featureGroup: FeatureGroup = featureGroup();

  constructor() {}

  ngAfterViewInit() {
    this.initializeMap();
    this.updateFeatureGroup();
    this.centerMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.map) {
      if (changes['markers'] || changes['labelPosition']) {
        this.featureGroup.clearLayers();
        this.updateFeatureGroup();
        if (!this.skipCentering) {
          this.centerMap();
        }
      }

      if (changes['height']) {
        this.map.getContainer().style.height = this.height;
        this.map.invalidateSize();
      }
    }
  }

  private initializeMap() {
    // set up the map
    this.map = map('map');
    //this.map.getContainer().style.height = this.height;
    tileLayer(this.tileURL, { attribution: this.attributionString }).addTo(this.map);

    // add an extra button to re-centre the map
    easyButton('fa-solid fa-crosshairs-simple fa-2xl', () => {
      this.centerMap(true);
    }).addTo(this.map);

    if (this.allowPinDrop) {
      // event handler for dropping a pin on the map
      this.map.on('click', e => {
        let lng = e.latlng.lng;
        while (lng < -180) lng += 360;
        while (lng > 180) lng -= 360;
        const pin: MapMarker = {
          id: '',
          lat: e.latlng.lat,
          lng: lng,
          colour: this.markerColour,
          labelColour: this.labelColour
        };
        this.dropPinEvent.emit(pin);
      });
    }

    this.featureGroup.addTo(this.map);
  }

  private updateFeatureGroup() {
    if (this.markers) {
      this.markers.forEach(marker => {
        const mapMarker = this.getMapMarker(
          marker.id,
          marker.lat,
          marker.lng,
          marker.title,
          marker.tooltip,
          marker.description,
          marker.draggable,
          marker.colour,
          marker.labelColour
        );

        // add marker to feature group
        this.featureGroup.addLayer(mapMarker);
      });
    }
  }

  private getMapMarker(
    id: string,
    lat: number,
    lng: number,
    title?: string,
    tooltip?: string,
    description?: string,
    draggable?: boolean,
    colour?: string,
    labelColour?: string
  ) {
    let icon = Marker.prototype.options.icon;
    // if colour specified, build an icon of the correct colour
    if (colour) {
      const label: string = title ? `<span style="${this.pinLabelStyles()} color:${labelColour}">${title}</span>` : '';
      icon = new DivIcon({
        iconAnchor: [3, 32],
        popupAnchor: [-1, -36],
        html: `<span style="${this.pinStyles} background-color:${colour}">${label}</span>`
      });
    }
    // create the marker
    const mapMarker = new Marker([lat, lng], {
      title: tooltip ? tooltip : title,
      draggable: draggable,
      icon: icon,
      bubblingMouseEvents: false
    });

    // add a popup if there is a description to show
    if (description) {
      mapMarker.bindPopup(description).openPopup();
    }

    // marker click event
    mapMarker.on('click', e => {
      const popup = e.target.getPopup();
      if (popup && popup.isOpen()) {
        // don't fire pin click if there is a popup that has just been opened
        return;
      }

      const pin: MapMarker = {
        id: id,
        lat: lat,
        lng: lng,
        title: title,
        tooltip: tooltip,
        description: description,
        draggable: draggable,
        colour: colour,
        labelColour: labelColour
      };
      this.clickPinEvent.emit(pin);
    });

    // marker is draggable?
    if (draggable) {
      mapMarker.on('dragend', e => {
        const pin: MapMarker = {
          id: id,
          lat: e.target.getLatLng().lat,
          lng: e.target.getLatLng().lng,
          title: title,
          tooltip: tooltip,
          description: description,
          draggable: draggable,
          colour: colour,
          labelColour: labelColour
        };
        this.dropPinEvent.emit(pin);
      });
    }

    return mapMarker;
  }

  private centerMap(manual: boolean = false) {
    if (this.featureGroup.getLayers().length > 0) {
      // if any marker is set to be focus, center on that
      const focusMarker = this.markers.find(m => m.focus);
      if (focusMarker && !manual) {
        this.map.setView(
          [focusMarker.lat, focusMarker.lng],
          this.defaultMapPosition ? this.defaultMapPosition.zoom : 15
        );
      } else {
        // fit the map to the bounds of all the markers
        // const bounds = latLngBounds(this.markerGroup.getLayers().map(marker => marker.getLatLng()));
        if (manual) {
          this.map.flyToBounds(this.featureGroup.getBounds());
        } else {
          this.map.fitBounds(this.featureGroup.getBounds());
        }
      }
    } else if (this.defaultMapPosition) {
      // use default centre point and zoom
      this.map.setView([this.defaultMapPosition.lat, this.defaultMapPosition.lng], this.defaultMapPosition.zoom);
    } else {
      // whole world view
      this.map.fitWorld();
    }
  }

  private pinLabelStyles() {
    if (this.labelAbovePin) {
      return `transform: rotate(-45deg) translate(-0.1em, calc(-${this.pinWidth / 2}px - 0.5em)); ${this.pinLabelStylesTemplate}`;
    }
    return `transform: rotate(-45deg) translate(-0.1em, 0.2em); ${this.pinLabelStylesTemplate}`;
  }
}

interface MapMarker {
  id: string;
  lat: number;
  lng: number;
  title?: string;
  tooltip?: string;
  description?: string;
  draggable?: boolean;
  colour?: string;
  labelColour?: string;
  focus?: boolean;
}

interface MapCentre {
  lat: number;
  lng: number;
  zoom: number;
}
