'use strict';

angular.module('shared.services.authInterceptor', [])
    .service('authInterceptor', ['$q', '$injector', 'forbiddenErrorService',
        function ($q, $injector, forbiddenErrorService) {
            var service = this;

            service.request = async function (config) {
                const noAuthHeaderMarkers = ['.html', '.json', '.css', '.svg', '.png', '.jpeg'];
                for (const marker of noAuthHeaderMarkers) {
                    if (config.url.includes(marker)) {
                        return config;
                    }
                }

                let accessToken = await $injector.get('ng1AuthService').getAccessToken();
                if (accessToken) {
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                }

                return config;
            };

            service.responseError = function (response) {
                if (response.status > 400 && response.status < 404) {
                    return $q(function () {
                        forbiddenErrorService.raiseError({ status: response.status })

                        return null;
                    }.bind(this));
                }
                return $q.reject(response);
            }.bind(this);
        }
    ]);
