angular
    .module('transport.routeUpdates', [
        'shared.directives.sbLoading',
        'shared.components.sbTitleBar',
        'shared.components.sbDataTable',
        'shared.components.sbRouteTimeline',
        'shared.constants',
        'transport.services.transportRouteUpdatesService',
        'transport.services.transportDirectionsService',
        'events.components.eventRegister',
    ])
    .component('transportRouteUpdates', {
        templateUrl:
            '/Scripts/app/transport/routeUpdates/transport-route-updates.template.html',
        controller: class TransportRouteUpdatesCtrl {
            // Dependencies
            $window: any;
            $interval: any;
            $anchorScroll: any;
            countDownIntervalFunction: any;
            refreshIntervalFunction: any;
            transportRouteUpdatesService: any;
            transportDirectionsService: any;
            eventRegistersService: any;
            moduleService: any;
            searchFilterTypes: any;
            tableProperties: any;

            // Variables
            updates: any[];
            filteredUpdates: any[];
            filteredTableData: any[];
            titleBarButtons: any[];
            tableHeader: any;
            isError: boolean;
            titleBarNoteTranslationParameters: any = {
                value: 0,
            };
            isTableProcessing: boolean = false;
            selectedItem: any = null;
            selectedStop: any;
            cellTypes: any;
            lastRefreshed: Date | null = null;
            allRoutesRefreshWasSkipped: boolean = false;
            isGPStrackingEnabled: boolean = false;
            tabEnum = {
                All: {
                    id: 0,
                    label: 'SB_All',
                },
                Inbound: {
                    id: 1,
                    label: '',
                },
                Outbound: {
                    id: 2,
                    label: '',
                },
            };
            selectedTabId: number = this.tabEnum.All.id;

            busMarkers: any[];
            isRefresh: boolean = false;
            routeMarkers: any[];
            markerColourEnum = [
                { colour: 'blue', label: 'white' },
                { colour: 'var(--gray-light)', label: 'black' },
                { colour: '#1ECD6E', label: 'black' },
                { colour: '#F59D00', label: 'black' },
                { colour: '#4B8FFA', label: 'black' },
            ];
            defaultMapPosition: any;

            static $inject = [
                '$window',
                '$interval',
                '$anchorScroll',
                'transportRouteUpdatesService',
                'transportDirectionsService',
                'eventRegistersService',
                'moduleService',
                'searchFilterTypes',
                'tableProperties',
            ];

            constructor(
                $window,
                $interval,
                $anchorScroll,
                transportRouteUpdatesService,
                transportDirectionsService,
                eventRegistersService,
                moduleService,
                searchFilterTypes,
                tableProperties
            ) {
                this.$window = $window;
                this.$interval = $interval;
                this.$anchorScroll = $anchorScroll;
                this.$anchorScroll.yOffset = 100;
                this.cellTypes = tableProperties.CellTypes;
                this.transportRouteUpdatesService =
                    transportRouteUpdatesService;
                this.transportDirectionsService = transportDirectionsService;
                this.eventRegistersService = eventRegistersService;
                this.moduleService = moduleService;
                this.searchFilterTypes = searchFilterTypes;
                this.tableProperties = tableProperties;
                this.titleBarButtons = [
                    {
                        message: 'SB_Refresh',
                        buttonClass: 'primary',
                        iconName: 'refresh',
                        buttonId: 'refreshRouteUpdates',
                        xsIconOnly: true,
                        action: this.refresh.bind(this),
                        isSubmitted: false,
                    },
                ];
                this.tableHeader = {
                    routeStartTime: {
                        name: 'SB_Transport_Route_Updates_Table_Start',
                        isDefault: true,
                        dateFormat: 'HH:mm',
                        cellClass: 'p-15',
                        isSortable: true,
                    },
                    busId: {
                        name: 'SB_Transport_Route_Updates_Table_Id',
                        cellClass: 'text-primary semi-bold',
                        isSortable: true,
                        isFilterable: true,
                    },
                    routeName: {
                        name: 'SB_Transport_Route_Updates_Table_Route_Name',
                        isSortable: true,
                        isFilterable: true,
                    },
                    busMonitorName: {
                        name: 'SB_Transport_Route_Updates_Table_Monitor',
                        isSortable: true,
                        isFilterable: true,
                    },
                    capacity: {
                        name: 'SB_Transport_Route_Updates_Table_Capacity',
                        cellClass: 'text-primary semi-bold',
                        isSortable: true,
                        hideIfEmpty: true,
                    },
                    totalStudents: {
                        name: 'SB_Transport_Route_Updates_Table_Total_Students',
                        cellClass: 'text-primary semi-bold',
                        isSortable: true,
                    },
                    totalExpectedStudents: {
                        name: 'SB_Transport_Route_Updates_Table_Expected',
                        cellClass: 'text-primary semi-bold',
                        isSortable: true,
                    },
                    numberLeftToEmbarkOrDisembark: {
                        name: 'SB_Transport_Route_Updates_Table_Left_To_Embark_Or_Disembark',
                        cellClass: 'text-primary semi-bold',
                        isSortable: true,
                    },
                    badge: {
                        name: 'SB_Transport_Route_Updates_Table_Status',
                        cellType: this.tableProperties.CellTypes.Badge,
                        isRowState: true,
                        isSortable: false,
                        isFilterable: true,
                        // Need to show full list, even if data doesn't contain one of every, so must manually define here
                        options: [
                            {
                                name: 'SB_All',
                                class: 'primary',
                                icon: 'transportAll',
                            },
                            {
                                name: 'SB_Transport_Route_Updates_Status_On_Schedule',
                                class: 'info',
                                icon: 'onSchedule',
                                id: 4,
                            },
                            {
                                name: 'SB_Transport_Route_Updates_Status_Late',
                                class: 'warning',
                                icon: 'warning',
                                id: 3,
                            },
                            {
                                name: 'SB_Transport_Route_Updates_Status_Not_Started',
                                class: 'neutral',
                                icon: 'garage',
                                id: 1,
                            },
                            {
                                name: 'SB_Transport_Route_Updates_Status_Completed',
                                class: 'success',
                                icon: 'tick',
                                id: 2,
                            },
                        ],
                    },
                    timeSlotName: {
                        name: 'SB_Transport_Route_Updates_Table_Time_Slot',
                        isSortable: true,
                        isFilterable: true,
                    },
                };
            }

            $onDestroy() {
                if (this.countDownIntervalFunction !== null) {
                    this.$interval.cancel(this.countDownIntervalFunction);
                }
                if (this.refreshIntervalFunction !== null) {
                    this.$interval.cancel(this.refreshIntervalFunction);
                }
            }

            $onInit() {
                this.moduleService
                    .getModules(this.$window.EveryBuddy.CurrentOrganisationId)
                    .then((responseData) => {
                        this.isGPStrackingEnabled =
                            responseData.transportModule.isGPSTrackingEnabled;
                        if (responseData.transportModule.defaultLatitude) {
                            this.defaultMapPosition = {
                                lat: responseData.transportModule
                                    .defaultLatitude,
                                lng: responseData.transportModule
                                    .defaultLongitude,
                                zoom: 15,
                            };
                        }
                    });
                this.countDownIntervalFunction = this.$interval(() => {
                    this.titleBarNoteTranslationParameters.value++;
                    if (!this.selectedItem && this.allRoutesRefreshWasSkipped) {
                        this.refresh();
                    }
                }, 1000);
                this.refresh();
                this.getDirectionNames();
            }

            refresh(fromInterval = false) {
                if (!fromInterval) {
                    if (this.refreshIntervalFunction != null) {
                        this.$interval.cancel(this.refreshIntervalFunction);
                    }
                    this.refreshIntervalFunction = this.$interval(() => {
                        this.refresh(true);
                    }, 60000);
                }
                const isInitial = this.lastRefreshed === null;
                this.lastRefreshed = new Date();
                this.titleBarNoteTranslationParameters.value = 0;
                if (this.selectedItem) {
                    this.getSelectedItem(this.selectedItem.eventId);
                    this.allRoutesRefreshWasSkipped = true;
                } else {
                    this.transportRouteUpdatesService
                        .getLiveRouteData()
                        .then((res) => {
                            this.updates = res.data.routes;
                            this.filterUpdates(!isInitial);
                            this.isError = this.updates === undefined;
                            this.titleBarButtons[0].isSubmitted = false;
                        });
                    this.allRoutesRefreshWasSkipped = false;
                }
            }

            filterUpdates(isRefresh: boolean = false) {
                if (this.selectedTabId === this.tabEnum.All.id) {
                    this.filteredUpdates = this.updates;
                } else {
                    this.filteredUpdates = this.updates.filter((route) => {
                        return (
                            (this.selectedTabId === this.tabEnum.Inbound.id &&
                                route.isSchoolbound) ||
                            (this.selectedTabId === this.tabEnum.Outbound.id &&
                                !route.isSchoolbound)
                        );
                    });
                }
                this.showBusMarkers(isRefresh);
            }

            getDirectionNames() {
                this.transportDirectionsService
                    .getTransportDirections()
                    .then((data) => {
                        const inboundDirection = data.find(
                            (direction) => direction.isInbound
                        );
                        const outboundDirection = data.find(
                            (direction) => !direction.isInbound
                        );
                        this.tabEnum.Inbound.label =
                            inboundDirection.nameOverride ||
                            inboundDirection.directionName;
                        this.tabEnum.Outbound.label =
                            outboundDirection.nameOverride ||
                            outboundDirection.directionName;
                    });
            }

            getSelectedItem(eventId: number) {
                // Show spinner if item not currently shown, otherwise just update page when loaded
                this.isTableProcessing = !this.selectedItem;
                this.transportRouteUpdatesService
                    .getRouteUpdateDetails(eventId)
                    .then((res) => {
                        this.selectedItem = res.data;
                        this.selectedItem.name =
                            this.selectedItem.busId +
                            ' : ' +
                            this.selectedItem.name +
                            ' : ' +
                            this.selectedItem.timeSlotName;
                        this.isTableProcessing = false;
                        this.titleBarButtons[0].isSubmitted = false;
                        this.showRouteMarkers();
                    });
            }

            tableOnClick(rowId: number) {
                this.selectedStop = null;
                this.isTableProcessing = true;
                this.getSelectedItem(rowId);
            }

            tableOnFilterChange(filteredData) {
                this.filteredTableData = filteredData;
                this.showBusMarkers();
            }

            onStopClick(stopId: number) {
                if (this.selectedStop?.routeStopId === stopId) {
                    return;
                }
                this.selectedStop = this.selectedItem.stops.find(
                    (stop) => stop.routeStopId === stopId
                );
                if (stopId) {
                    this.$anchorScroll.yOffset = 100;
                    this.$anchorScroll('routeStopDetails');
                }
            }

            setTabId(tabId: number) {
                this.selectedTabId = tabId;
                this.filterUpdates();
            }

            showBusMarkers(isRefresh: boolean=false) {
                if (this.isGPStrackingEnabled) {
                    var markers: MapMarker[] = [];
                    const noAdditionalTableFiltersApplied: boolean =
                        this.filteredTableData === undefined;
                    this.filteredUpdates.forEach((row) => {
                        const includeRowOnMapDisplay: boolean =
                            noAdditionalTableFiltersApplied ||
                            this.filteredTableData?.find(
                                (e) => e.eventId.id == row.eventId
                            );
                        if (includeRowOnMapDisplay && row.lastKnownLocation) {
                            markers.push({
                                id: row.eventId.id
                                    ? row.eventId.id
                                    : row.eventId,
                                title: row.busId.label
                                    ? row.busId.label
                                    : row.busId,
                                lat: row.lastKnownLocation.latitude,
                                lng: row.lastKnownLocation.longitude,
                                colour: this.markerColourEnum[row.badge.id]
                                    .colour,
                                labelColour:
                                    this.markerColourEnum[row.badge.id].label,
                            });
                        }
                    });
                    this.busMarkers = markers;
                    this.isRefresh = isRefresh;
                }
            }

            showRouteMarkers() {
                if (this.isGPStrackingEnabled) {
                    var markers: any[] = [];
                    var i = 1;
                    this.selectedItem.stops.forEach((stop) => {
                        if (stop.latitude && stop.longitude) {
                            markers.push({
                                id: stop.routeStopId,
                                title: i,
                                tooltip: stop.name,
                                lat: stop.latitude,
                                lng: stop.longitude,
                                colour: this.markerColourEnum[0].colour,
                                labelColour: this.markerColourEnum[0].label,
                            });
                        }
                        i++;
                    });
                    if (this.selectedItem.lastKnownLocation) {
                        markers.push({
                            title: this.selectedItem.busId,
                            lat: this.selectedItem.lastKnownLocation.latitude,
                            lng: this.selectedItem.lastKnownLocation.longitude,
                            focus: true,
                        });
                    }
                    this.routeMarkers = markers;
                }
            }

            busMarkerClick(e) {
                this.getSelectedItem(e.id);
            }

            routeMarkerClick(e) {
                if (!isNaN(e.id)) {
                    this.onStopClick(e.id);
                }
            }
        },
    });

interface MapMarker {
    id: string;
    lat: number;
    lng: number;
    title?: string;
    tooltip?: string;
    description?: string;
    draggable?: boolean;
    colour?: string;
    labelColour?: string;
    focus?: boolean;
}
