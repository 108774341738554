'use strict';

import { EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { TransportCapacityWarningComponent } from 'src/app/transport/components/transport-capacity-warning/transport-capacity-warning.component';
import { MapDisplayComponent } from 'src/app/shared/components/map-display/map-display.component';

angular.module('transportModule', [
  'ngMessages',
  'pascalprecht.translate',
  'ui.bootstrap',

  'group.controllers.multiPersonPickerPopupController',

  'person.services.personPickerService',
  'person.services',

  'profile.controllers.profilePopupController',

  'reports.components.reportContainer',
  'transport.changeRequests.changeRequestsApprovedOrRejected',
  'transport.changeRequests.studentPickerWithDiaryEvents',
  'transport.changeRequests.transportPendingRequests',

  'transport.constants',
  'transport.configuration.archiving.transportConfirmArchiving',
  'transport.configuration.archiving.transportConfirmDeleteEvents',
  'transport.configuration.transportFeatures',
  'transport.configuration.transportDirections',
  'transport.configuration.transportMethodAdd',
  'transport.configuration.transportMethodEdit',
  'transport.configuration.transportMethods',
  'transport.configuration.transportOperatingDays',
  'transport.configuration.transportRoutes',
  'transport.configuration.routes.transportRouteAdd',
  'transport.configuration.routes.transportRouteEdit',
  'transport.configuration.routeStopTimes',
  'transport.configuration.transportStops',
  'transport.configuration.transportStopAdd',
  'transport.configuration.transportStopEdit',
  'transport.configuration.transportTimeslots',
  'transport.configuration.transportTimeslotAdd',
  'transport.configuration.transportTimeslotEdit',
  'transport.configuration.timetable.cloneTimetableRoute',
  'transport.configuration.timetable.transportTimetables',
  'transport.configuration.timetable.transportTimetableAdd',
  'transport.configuration.timetable.transportTimetableEdit',
  'transport.configuration.timetable.transportTimetableList',
  'transport.configuration.timetable.transportTimetableRoutes',
  'transport.configuration.timetableCalendar.transportTimetableCalendars',
  'transport.configuration.timetableCalendar.transportTimetableCalendarAdd',
  'transport.configuration.timetableCalendar.transportTimetableCalendarEdit',
  'transport.configuration.timetableCalendar.transportTimetableCalendarList',

  'transport.dashboard.transportDashboard',

  'transport.reports.transportReports',

  'transport.routeUpdates',

  'transport.services.changeRequestService',
  'transport.services.pupilTransportPreferenceService',
  'transport.services.transportOperatingDaysService',
  'transport.services.transportDirectionsService',

  'transport.services.configurationFeaturesService',
  'transport.services.transportTimetablesService',
  'transport.services.transportTimetableCalendarsService',
  'transport.services.transportConfigurationMethodService',
  'transport.services.transportConfigurationStopsService',
  'transport.services.transportConfigurationRoutesService',

  'transport.pupilPreferences.components.confirmDeletePreferences',
  'transport.pupilPreferences.components.massPupilTransportAssignmentMain',
  'transport.pupilPreferences.components.massAssignmentMethodsAndTimetables',
  'transport.pupilPreferences.components.preferenceUpdatesFeedback',
  'transport.pupilPreferences.components.pupilPreferenceSelections',

  'reports.components.reportContainer',

  'shared.components.sbAutoComplete',
  'shared.components.sbIcon',
  'shared.components.sbLabelList',
  'shared.components.sbDatePicker',
  'shared.components.sbColumnHeader',
  'shared.components.sbSideMenuNavPill',
  'shared.components.sbUpsell',
  'shared.directives.cloakTranslate',
  'shared.directives.ngBindHtmlDataLabelAttr',
  'shared.directives.ngBindHtmlPlaceholder',
  'shared.directives.ngBindHtmlTitleAttr',
  'shared.directives.sbCheckBox',
  'shared.directives.sbCurrencyInput',
  'shared.directives.sbLoading',
  'shared.components.sbSubmitButton',
  'shared.components.subMenu',
  'shared.directives.updateLanguage',
  'shared.services.authInterceptor',
  'shared.services.cachedLookupService',
  'shared.services.moduleService',
  'shared.services.simpleAlertService',

  'groups.services.groupService'
]);
angular
  .module('transportModule')
  .config([function () {}])
  .directive(
    'sbMapDisplay',
    downgradeComponent({
      component: MapDisplayComponent,
      inputs: [
        'defaultMapPosition',
        'markers',
        'allowPinDrop',
        'tileURL',
        'attributionString',
        'markerColour',
        'labelColour',
        'height',
        'skipCentering'
      ],
      outputs: ['dropPinEvent', 'clickPinEvent']
    })
  )
  .directive(
    'sbTransportCapacityWarning',
    downgradeComponent({
      component: TransportCapacityWarningComponent,
      inputs: ['exceedCapacityByChangeRequests']
    })
  )
  .component('ng1TransportConfigMethods', {
    template: '<transport-methods></transport-methods>'
  })
  .component('ng1TransportConfigOperatingDays', {
    template: '<transport-operating-days></transport-operating-days>'
  })
  .component('ng1TransportConfigDirections', {
    template: '<transport-directions></transport-directions>'
  })
  .component('ng1TransportConfigTimeSlots', {
    template: '<transport-timeslots></transport-timeslots>'
  })
  .component('ng1TransportMassPupilAssignment', {
    template: '<mass-pupil-transport-assignment-main></mass-pupil-transport-assignment-main>'
  })

  .component('ng1TransportConfigStops', {
    template: '<transport-stops></transport-stops>'
  })
  .component('ng1TransportConfigRoutes', {
    template: '<transport-routes></transport-routes>'
  })
  .component('ng1TransportConfigTimetableCalendars', {
    template: '<transport-timetable-calendars></transport-timetable-calendars>'
  })
  .component('ng1TransportConfigTimetables', {
    template: '<transport-timetables></transport-timetables>'
  })
  .component('ng1TransportConfigFeatures', {
    template: '<transport-features></transport-features>'
  })
  .component('ng1TransportConfigRouteStopTimes', {
    template: '<transport-route-stop-times></transport-route-stop-times>'
  })
  .component('ng1TransportReports', {
    template: '<transport-reports></transport-reports>'
  })
  .component('ng1TransportChangeRequests', {
    template: '<transport-change-requests></transport-change-requests>'
  })
  .component('ng1TransportChangeRequestsPending', {
    template:
      '<transport-pending-requests transport-change-requests="$ctrl.changeRequests" on-change-requests-updated="$ctrl.changesRequestsUpdated"></transport-pending-requests>',
    controller: class Ng1TransportChangeRequestsPendingCtrl {
      changeRequestService: any;
      toastService: any;
      changeRequests: any[];

      changesRequestsUpdated = new EventEmitter<void>();

      static $inject = ['changeRequestService', 'toastService'];

      constructor(changeRequestService, toastService) {
        this.changeRequestService = changeRequestService;
        this.toastService = toastService;
        this.changesRequestsUpdated.subscribe(() => this.loadData());
      }

      $onInit() {
        this.loadData();
      }

      loadData() {
        this.changeRequestService
          .getPendingChangeRequestsForApproval()
          .then(data => {
            this.changeRequests = data;
          })
          .catch(() => {
            this.toastService.getError('SB_Error_loading');
          });
      }
    }
  })
  .component('ng1TransportChangeRequestsProcessed', {
    template:
      '<change-requests-approved-or-rejected transport-change-requests="$ctrl.changeRequests" on-change-requests-updated="$ctrl.changesRequestsUpdated"></change-requests-approved-or-rejected>',
    controller: class Ng1TransportChangeRequestsProcessedCtrl {
      changeRequestService: any;
      toastService: any;
      changeRequests: any[];

      changesRequestsUpdated = new EventEmitter<void>();

      static $inject = ['changeRequestService', 'toastService'];

      constructor(changeRequestService, toastService) {
        this.changeRequestService = changeRequestService;
        this.toastService = toastService;
      }

      $onInit() {
        this.loadData();
        this.changesRequestsUpdated.subscribe(() => this.loadData());
      }

      loadData() {
        this.changeRequestService
          .getFutureChangeRequestsApprovedOrRejected()
          .then(data => {
            this.changeRequests = data;
          })
          .catch(() => {
            this.toastService.getError('SB_Error_loading');
          });
      }
    }
  })
  .component('ng1TransportRouteUpdates', {
    template: '<transport-route-updates></transport-route-updates>'
  });
