angular.module('events.services.eventRegisterTransportService', [
    'shared.services.registerStateService',
    'transport.services.configurationFeaturesService',
    'events.constants'])
    .factory('eventRegisterTransportService',
        [
            '$filter',
            'registerStateService',
            'transportAttendeeStateEnum',
            'configurationFeaturesService',
            function ($filter,
                registerStateService,
                transportAttendeeStateEnum,
                configurationFeaturesService) {
                var service = this;

                service.registerStateTypeEnum = registerStateService.getRegisterStateTypeEnum();

                service.transportAttendeeStateEnum = transportAttendeeStateEnum;

                service.getIsGPSTrackingEnabled = async () => {
                    service.isGPSTrackingEnabled = await configurationFeaturesService.getFeaturesConfiguration()
                        .then(({ data: { isGPSTrackingEnabled, isActive } = {} }) => isActive && isGPSTrackingEnabled);

                    return service.isGPSTrackingEnabled;
                };

                service.isExcused = function (item) {
                    return item && (item.registerState == service.registerStateTypeEnum.Excused);
                }.bind(service);

                service.isTransportEvent = function (register) {
                    return register.eventType == 'Transport';
                }

                service.isRouteTransportEvent = function (register) {
                    return service.isTransportEvent(register) && register.hasRoutes;
                }.bind(service);

                service.transportSections = [
                    {
                        title: 'SB_Expected',
                        filter: function (item) { return !service.isExcused(item) && item.transportIsExpected }.bind(service),
                        registerCanBeTaken: true,
                        canExit: true,
                        groupByStop: true,
                        stopIcon: 'location-pin',
                        expanded: true
                    },
                    {
                        title: 'SB_Not_Expected',
                        filter: function (item) { return !service.isExcused(item) && !item.transportIsExpected }.bind(service),
                        registerCanBeTaken: false,
                        canExit: false,
                        groupByStop: false,
                        expanded: false,
                        moveToExpected: true
                    },
                    {
                        title: 'SB_Excused',
                        filter: service.isExcused,
                        registerCanBeTaken: true,
                        canExit: false,
                        groupByStop: false,
                        expanded: false
                    }
                ];

                service.getAttendeeSections = function (register) {
                    var attendeeSections = [];
                    if (!service.isTransportEvent(register)) {

                        attendeeSections.push({
                            config: {
                                registerCanBeTaken: true,
                                expanded: true
                            },
                            subsections: [{ attendees: register.attendants }]
                        });

                        angular.forEach(register.attendants, function (attendee) {
                            attendee.registerCanBeTaken = true;
                        });

                        return attendeeSections;
                    }

                    angular.forEach(this.transportSections, function (transportSection) {
                        var attendees = register.attendants.filter(transportSection.filter);

                        angular.forEach(attendees, function (attendee) {
                            attendee.registerCanBeTaken = transportSection.registerCanBeTaken;
                        });

                        var attendeeSection =
                        {
                            config: transportSection,
                            attendees: attendees,
                            attendeeCount: attendees.length,
                            subsections: []
                        };

                        // Just list of attendees if not a groupByStop section or not stops
                        if (!transportSection.groupByStop || !register.transportRouteStops) {
                            attendeeSection.subsections.push(
                                {
                                    attendees: attendees
                                }
                            );

                            attendeeSections.push(attendeeSection);
                            return attendeeSections;
                        }

                        angular.forEach(register.transportRouteStops, function (routeStop) {
                            var stopAttendees = $filter('filter')(attendees, { 'transportAttendeeRouteStopId': routeStop.id }, true);
                            if (stopAttendees.length > 0) {
                                attendeeSection.subsections.push(
                                    {
                                        title: routeStop.name,
                                        stopAddress: stopAttendees[0].formattedAddress,
                                        iconName: transportSection.stopIcon,
                                        routeStopTime: $filter('date')(stopAttendees[0].transportAttendeeRouteStopTime, 'HH:mm'),
                                        attendees: stopAttendees
                                    }
                                );
                            }
                        });
                        attendeeSections.push(attendeeSection);
                    }.bind(this));
                    return attendeeSections;
                }.bind(this);

                return service;
            }
        ]);